import styled, { createGlobalStyle } from "styled-components";
import 'antd/dist/antd.min.css';
import { Drawer } from "antd";


export const GlobalStyle = createGlobalStyle`
    :root {
        --pink: #EF5DA8;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        /* percentege for acessibilty configs keep ok on device */
        @media (max-width: 1080px) {
            font-size: 93.75%; 
        }
        
        @media (max-width: 720px) {
            font-size: 87.5%; 
        }
    }

    body {
        background-color: #f3f3f3ee;
        /* padding-top: 6rem; */
    }

    body, input, textarea, button {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .ant-form-item-required::before{
        display: none !important;
    }

    input:focus, input:hover, .ant-select-selector:focus, .ant-select-selector:hover {
        border-color: var(--pink) !important;
        box-shadow: 0 0 0 2px rgb(239 93 168 / 20%) !important;
    }

    .ant-form-item {
        .ant-select:focus, .ant-select:hover {
            .ant-select-arrow {
                color: var(--pink) !important;
            }
        }
    } 

    input {
        border-radius: 5px !important;
        padding: 0.5rem !important;
        border: 1px solid #DDDBDD !important;
        background-color: #FCFCFC !important;
        width: 100% !important;
    }
`

export const DrawerStyled = styled(Drawer)`
    top: 6rem;

    .ant-drawer-body {
        background-color: #fdfbfbee;

        .menuItem {
            margin: 0;
            padding: 1rem;
            display: block;
            color: #000;
        }

        .menuItem:hover {
            cursor: pointer;
            background-color: #f7f4f4;
        }
    }
`
