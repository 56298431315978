import styled from "styled-components";
import mainSectionImg from "./assets/mainSectionBackground2.jpg"
import secundSectionBackground from "./assets/secundSectionBackground.png"
import fourthSectionBackground from "./assets/sectionFourBackground.png"

export const Container = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 6rem;
`

export const MainSection = styled.div`
    width: 100%;

    padding: 0 0.5rem; 

    height: 45vh;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: 
        linear-gradient(
            rgba(0, 0, 0, 0.5), 
            rgba(0, 0, 0, 0.5)
        ),
        url(${mainSectionImg});
    
    background-size: cover;
    background-position: center;

    h1 {
        font-size: 1.5rem;
        color: #fff;
        text-align: center;
    }
    
    p {
        font-size: 0.8rem;
        margin-top: 1rem;
    }

    .mainButton {
        font-size: 1rem;
        padding: 1rem 2rem;
        border: 0;
        background-color: #000;
        color: #fff;
        border-radius: 0.25rem;
        margin-top: 2rem;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8)
        }
    }
`

export const SecundSection = styled.div`
    background-color: #fff;

    
    .resultsWrapper {
        display: flex;
        justify-content: space-between;
        color: #000;
        
        margin: 0 auto;
        
        padding: 3rem 1rem 0rem 1rem;
        margin-bottom: 2rem;

        h2 {
            font-weight: bold;
        }
    }

    .result {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 6rem;
        text-align: center;

        h2 {
            margin: 0;
            font-weight: bolder;
        }
    }

    .cta {
        display: flex;
        flex-direction: column;
        align-items: center;

        height: 18rem;
        margin: 0 auto;
        
        padding: 4rem 1rem;

        p {
            color: #fff;
            font-size: 0.7rem;

            span {
                font-weight: bold;
            }
        }

    .mainButton {
        font-size: 1rem;
        padding: 1rem 2rem;
        border: 0;
        background-color: #fff;
        color: #000;
        border-radius: 0.25rem;
        margin-top: 2rem;
        font-weight: 700;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8)
        }
    }

        background-image: url(${secundSectionBackground});

        background-size: cover;
    }

    @media (min-width: 500px) {
        .resultsWrapper {
            padding: 3rem 4rem 0rem 4rem;
        }
        .result {
            font-size: 1.2rem;
        }
        .cta {
            padding: 5rem 4rem;
        }
        .cta p {
            font-size: 0.8rem;
            margin: 0;
        }
    }
` 

export const ThirdSection = styled.div`
    width: 100%;

    background-color: #fff;

    max-width: 1120px;
    margin: 0 auto;
    
    padding: 2rem 1rem 2rem 1rem;

    h2 {
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 1.2rem;

        span {
            font-weight: bolder;
            font-size: 1.3rem;
        }
    }

    h3 {
        font-size: 1.1rem;
    }

    .storyBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;

        margin-bottom: 1rem;

        img {
            height: 10rem;
        }
    }
`

export const FourthSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 20rem;

    max-width: 1120px;
    margin: 0 auto;
    
    padding: 2rem 1rem 0rem 1rem;

    h2 {
        text-align: center;
        font-size: 1.6rem;
        color: #fff
    }

    .mainButton {
        font-size: 1rem;
        padding: 1rem 2rem;
        border: 0;
        background-color: #fff;
        color: #000;
        border-radius: 0.25rem;
        margin-top: 2rem;
        font-weight: 700;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8)
        }
    }

    background-image: url(${fourthSectionBackground});

    background-size: cover;
    background-position: center;
    
`

export const FifthSection = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    max-width: 1120px;
    margin: 0 auto;

    padding: 2rem 1rem;

    div {
        text-align: center;
    }
`

export const Footer = styled.div`
    text-align: center;
    max-width: 1120px;
    margin: 0 auto;

    padding: 2rem 1rem;

    background-color: #000;
    
    h2 {
        color: #fff;
        font-size: 1.2rem;
    }

    div {
        width: 100%;
        justify-content: center;
        display: flex;
        gap: 1rem;
        font-size: 2rem;
    }
`

export const WhatsappButton = styled.a`
    width: 4rem;
    height: 4rem;

    border-radius: 50%;
    background-color: #25d366;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.8)
    }
`