import React from 'react';
import ReactDOM from 'react-dom/client';
// import { App } from './App';
import { App } from './AppMvp';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/trabalhe-conosco' element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);